<template>
  <!-- 原单 -->
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <OrderHead :data="order" />
    <van-cell title="订单号" :value="order.orderNo" class="auto-cell" />
    <van-cell title="下单时间" :value="order.createTime" />
    <van-cell
      v-if="!(order.corp=='差旅壹号'&&order.bz3)"
      title="订单状态"
      :value="order.bz1"
      :value-class="order.bz1|btnType"
    />
    <van-cell
      title="订单状态"
      v-else
      is-link
      :to="{name:'paymentHotelRefund', query: {id:order.bz3}}"
      :value="'查看退票信息'"
    />
    <van-cell title="订单总额" :value="'￥'+order.totalPrice" value-class="red" />
    <a :href="'tel:'+phone">
      <van-cell v-show="phone">
        <template #title>
          客服
          <Logo :corp="order.corp" />
        </template>
        <template #default>
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </template>
      </van-cell>
    </a>

    <div class="gap"></div>

    <van-cell title="房间类型" :value="order.roomTypeName" />
    <van-cell title="到店日期" :value="order.arrivalDate|comverTime('YYYY-MM-DD')" />
    <van-cell title="离店日期" :value="order.departureDate|comverTime('YYYY-MM-DD')" />
    <van-cell title="支付方式" :value="order.paymentType" />

    <div class="gap"></div>

    <van-panel title="人员信息" class="peoList">
      <van-cell v-for="(item,i) in showPeoList" :key="i" :label="item.mobile?('预留手机号：'+item.mobile):''" v-if="item">
        <template #title>
          <div class="left-cont">
            <van-icon name="stop" color="#44b9fa" />
            <span class="custom-title">{{item.name}}</span>
          </div>
        </template>
        <template #right-icon>
          <!-- <span class="roomNumber" v-if="item.roomNo">{{item.roomNo}}</span> -->
        </template>
      </van-cell>
    </van-panel>
    <el-tooltip
      v-if="order.bz1=='确认中'"
      class="item"
      effect="dark"
      content="订单确认中，如手动取消预定失败，请拨打客服电话取消预定。"
      placement="top"
    >
      <van-notice-bar left-icon="volume-o" text="订单确认中，如手动取消预定失败，请拨打客服电话取消预定." />
    </el-tooltip>

    <div class="gap"></div>
    <van-button
      class="bottomBtn"
      type="info"
      size="large"
      @click="onConfirm"
      :disabled="cancelLoading"
      :loading="confirmLoading"
      v-show="showconfirmbtnArr.includes(order.bz1)"
      loading-text="请稍候..."
    >确认提交</van-button>

    <div class="gap"></div>
    <van-button
      v-if="!(order.corp=='差旅壹号'&&order.bz3)"
      class="bottomBtn"
      type="danger"
      size="large"
      @click="show=true"
      :disabled="confirmLoading"
      :loading="cancelLoading"
      loading-text="请稍候..."
      v-show="showbtnArr.includes(order.bz1)"
    >取消预订</van-button>

    <div class="gap"></div>

    <van-dialog :beforeClose="beforeClose" v-model="show" title="取消预订" show-cancel-button>
      <van-form ref="editCompliant" class="explanCont">
        <van-field
          readonly
          clickable
          name="picker"
          :value="reason"
          label="退订原因"
          placeholder="请选择退订原因"
          @click="reasonShow = true"
          :rules="[{ required: true, message: '理由不能为空' }]"
        />
      </van-form>
    </van-dialog>

    <!-- 退票理由弹窗 -->
    <van-popup v-model="reasonShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="cancelTypeList"
        @cancel="reasonShow = false"
        @confirm="selectReason"
      />
    </van-popup>
  </van-pull-refresh>
</template>

<script>
import Logo from "@/components/Logo";
import { mapGetters } from "vuex";
import {
  hotelOrderReload,
  cancelHotelOrder,
  commitHotelOrder,
} from "@/api/hotel";
import OrderHead from "./components/OrderHead";
import {
  HotelRefundBtn,
  HotelConfirmBtn,
  fetchPhone,
} from "@/common/config.js";
import { getStatusColor } from "@/services/hotel.js";
export default {
  computed: {
    ...mapGetters(["orderItem"]),
    showPeoList: function () {
      let result = this.peoList || [];
      for (let i = 0; i < result.length; i++) {
        const peo1 = result[i];
        if (peo1)
          for (let j = i + 1; j < result.length; j++) {
            const peo2 = result[j];
            if (peo2 && peo1.roomNo && peo1.roomNo == peo2.roomNo) {
              peo1.name += "、" + peo2.name;
              delete result[j];
            }
          }
      }
      return result;
    },
  },
  components: { OrderHead, Logo },
  data() {
    return {
      phone: "", //服务商电话
      confirmLoading: false,
      cancelLoading: false,
      isLoading: false, // 刷新
      canRefresh: true, // 可否刷新
      order: {}, //订单信息
      peoList: [], // 人员列表
      showbtnArr: HotelRefundBtn,
      showconfirmbtnArr: HotelConfirmBtn,
      show: false, // 退订原因开关
      reason: "", // 退订原因
      reasonShow: false, // 退订弹窗开关
      // 退订原因选项
      cancelTypeList: [
        "1-酒店质量差",
        "2-行程取消",
        "3-信息填错",
        "4-酒店信息错误",
        "5-其他",
      ],
    };
  },
  filters: {
    btnType: function (data) {
      return getStatusColor(data);
    },
  },
  methods: {
    selectReason(value) {
      this.reason = value;
      this.reasonShow = false;
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 500);
    },
    // ajax 获取数据
    fetchData(f = true) {
      this.phone = fetchPhone(this.orderItem.corp);
      if (f) this.canRefresh = true;
      if (this.canRefresh)
        hotelOrderReload({
          corp: this.orderItem.corp,
          orderNo: this.orderItem.orderNo,
          outOrderNo: this.orderItem.outOrderNo,
        }).then((response) => {
          this.order = response.order;
          this.peoList = response.orderItem;
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
            this.$forceUpdate();
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。",
        });
        this.isLoading = false;
      }
    },
    // 取消出票
    cancel(cb = () => {}) {
      this.cancelLoading = true;
      cancelHotelOrder({
        orderNo: this.order.orderNo,
        originalOrderNo: this.order.bz3,
        reason: this.reason,
        corp: this.order.corp,
      })
        .then((response) => {
          this.$toast.success("申请取消成功，请稍后。");
          this.fetchData();
          this.cancelLoading = false;
          cb();
        })
        .catch(() => {
          this.show = false;
          this.cancelLoading = false;
          cb();
        });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.editCompliant
          .validate()
          .then(() => {
            this.cancel(done);
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onConfirm() {
      this.confirmLoading = true;
      commitHotelOrder({
        orderNo: this.order.orderNo,
        outOrderNo: "",
        originalOrderNo: this.order.bz3,
        itemId: "",
        totalPrice: 0,
        corp: this.order.corp,
      })
        .then((response) => {
          // 如果经费不足提示。
          if (!response || !response.success) {
            this.confirmLoading = false;
            if(response&&response.msg)return this.$toast.fail(response.msg);
            if(this.order.corp=="行旅") return this.$toast.success("申请成功，请稍后。");
            return this.$toast.fail("暂无返回信息");
          }
          this.$toast.success("申请成功，请稍后。");
          this.fetchData();
          this.confirmLoading = false;
          this.showconfirmbtnArr = [];
        })
        .catch(() => {
          this.$toast.success("申请成功，请稍后。");
          this.cancelLoading = false;
        });
    },
  },
  activated() {
    this.peoList = [];
    this.order = {};
    this.peoList = [];
    // this.isLoading = true;
    this.reason = "";
    this.fetchData();
  },
};
</script>

<style lang="less" scoped>
@import '../../styles/theme.less';
.roomNumber {
  color: @gray-6;
  display: inline-block;
  border: 1px solid lighten(@blue, 10%);
  padding: 2px 11px;
  line-height: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 4px 4px 2px 0 lighten(@blue, 10%);
}
</style>

<style lang="less">
@import '../../styles/theme.less';
.peoList .van-panel__header-value {
  color: @gray-6;
}
</style>